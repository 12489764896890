import { Any, JsonObject, JsonProperty } from 'json2typescript';

import { Category } from 'models/Category';
import { Holiday } from 'models/Holiday';

export const statusLang: { [key:string]: string } = {
	active: 'Activo',
	inactive: 'Inactivo',
	inactive_today: 'Inactivo Hoy',
};

@JsonObject('StoreDeliveryPrice')
export class StoreDeliveryPrice {
	@JsonProperty('end', Number) end: number = 4;
    @JsonProperty('surcharge', Number) surcharge: number = 0;
    @JsonProperty('price', Number) price: number = 0;
    @JsonProperty('min_time', Number) minTime: number = 30;
    @JsonProperty('max_time', Number) maxTime: number = 60;
}

@JsonObject('Schedule')
export class Schedule {
	@JsonProperty('start', Number) start: number = 0;
	@JsonProperty('end', Number) end: number = 0;

	get startFormated() {
		return Schedule.format(this.start);
	}

	set startFormated(time: string) {
		this.start = Schedule.parse(time);
	}

	get endFormated() {
		return Schedule.format(this.end);
	}

	set endFormated(time: string) {
		this.end = Schedule.parse(time);
	}

	/**
	 * Format time in minutes to HH:mm
	 **/
	static format(time: number): string{
		let minutes = time % 60
		let hour = (time - minutes) / 60;

		return `${('00'+hour).slice(-2)}:${('00'+minutes).slice(-2)}`;
	}

	/**
	 * Convert time in format HH:mm to minutes
	 **/
	static parse(time: string): number{
		let timeArray = time.split(':').map(n => parseInt(n));

		return timeArray[0] * 60 + timeArray[1];
	}

	/**
	 * Create a schedule with the actual time
	 **/
	static createDefault(): Schedule{
		let now = new Date();
		let schedule = new Schedule();
		schedule.start = now.getHours() * 60;
		schedule.end = (now.getHours() + 1) * 60;

		return schedule;
	}
}

@JsonObject('WeeklySchedules')
export class WeeklySchedules {
	@JsonProperty('0', [Schedule]) '0': Schedule[] = [];
	@JsonProperty('1', [Schedule]) '1': Schedule[] = [];
	@JsonProperty('2', [Schedule]) '2': Schedule[] = [];
	@JsonProperty('3', [Schedule]) '3': Schedule[] = [];
	@JsonProperty('4', [Schedule]) '4': Schedule[] = [];
	@JsonProperty('5', [Schedule]) '5': Schedule[] = [];
	@JsonProperty('6', [Schedule]) '6': Schedule[] = [];
}

@JsonObject('StoreHoliday')
export class StoreHoliday {
	@JsonProperty('holiday', Holiday) holiday: Holiday = new Holiday();
	@JsonProperty('open', Boolean) open: boolean = true;
	@JsonProperty('open_schedule', Schedule) openSchedule: Schedule = new Schedule();
}

@JsonObject('Store')
export class Store {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('name', String) name: string = '';
	@JsonProperty('status', String, true) status: string = '';
	@JsonProperty('categories', [Category], true) categories: Category[] = [];
	@JsonProperty('phone', String, true) phone: string = '';
	@JsonProperty('email', String, true) email: string | undefined;
	@JsonProperty('address', String, true) address: string = '';
	@JsonProperty('zone', String, true) zone: string = '';
	@JsonProperty('has_mercadopago', Boolean, true) hasMercadopago?: boolean;
    @JsonProperty('schedules', WeeklySchedules, true) schedules: WeeklySchedules | undefined;
	@JsonProperty('holidays', [StoreHoliday], true) holidays?: StoreHoliday[];
	@JsonProperty('has_mobbex', Boolean, true) hasMobbex?: boolean;
	@JsonProperty('enable_mobbex', Boolean, true) enableMobbex?: boolean;

	@JsonProperty('delivery', Boolean, true) delivery?: boolean;
	@JsonProperty('custom_delivery', Boolean, true) customDelivery?: boolean;
	@JsonProperty('delivery_surcharge_status', String, true) deliverySurchargeStatus?: string;
	@JsonProperty('delivery_surcharge_label', String, true) deliverySurchargeLabel?: string;
	@JsonProperty('delivery_price', [StoreDeliveryPrice], true) deliveryPrice?: StoreDeliveryPrice[];
    @JsonProperty('delivery_distance', Number, true) deliveryDistance?: number;
	@JsonProperty('external_data', Any, true) externalData: any;
	
	public get fullNameDescription() : string {
		return `${this.externalData?.format} - ${this.externalData?.number} - ${this.name}`;
	}
	
	/**
	 * Get order status formated
	 **/
	public get statusFormatted(): string{
		return statusLang[this.status] || this.status;
	}
}