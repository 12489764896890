import Menu from './components/Menu';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading, IonModal } from '@ionic/react';

import InfoHelper, { useIsLoadingShown } from 'libraries/InfoHelper';
import { useActiveModals } from 'libraries/ModalController';
import Authentication, { useIsAuthenticated, usePermissions } from 'libraries/Authentication';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import StockAlertService from 'services/StockAlertService';

const Login = lazy(() => import('./pages/Login/Login'));
const OrdersHistory = lazy(() => import('./pages/Orders/OrdersHistory'));
const Offers = lazy(() => import('./pages/Offers/Offers'));
const OffersReduction = lazy(() => import('./pages/Offers/Reduction/OffersReduction'));
const AnalyticsPage = lazy(() => import('./pages/Analytics/Analytics'));
const StockAlertsPage = lazy(() => import('./pages/StockAlerts/StockAlerts'));

const App: React.FC = () => {
	const showLoading = useIsLoadingShown();
	const isAuthenticated = useIsAuthenticated();
	const [appInitialized, setAppInitialized] = useState(false);
	const [appError, setAppError] = useState(false);
    const activeModals = useActiveModals();
	const permissions = usePermissions();

	// Initialize app
	useEffect(() => {
		initializeApp();

		async function initializeApp() {
			try {
				await Authentication.initialize();
				await StockAlertService.initialize();

				setAppInitialized(true);
			}
			catch(error) {
				console.log(error);

				InfoHelper.showErrorAlert({ 
					header: 'Ah ocurrido un error',
					subHeader: 'Intente cargar la pagina nuevamente',
					backdropDismiss: false
				});

				setAppError(true);
			}
		}
	}, []);

	return (
		<IonApp>
			<IonLoading isOpen={showLoading || (!appInitialized && !appError)} />
			
			{appInitialized && <IonReactRouter>
					<Suspense fallback={
						<IonLoading isOpen={!showLoading} />
					}>
	
						<IonSplitPane contentId="main">
							{isAuthenticated && <Menu />}
	
							<IonRouterOutlet id="main">
	
								{ isAuthenticated ? [
									// Render when is authenticated
									<Route key="/offers" path="/offers" component={Offers} exact />,
									
									...(permissions.reductionCreate ? [<Route key="/offers-reduction-store" path="/offers-reduction-store" render={(props) => <OffersReduction type="store" {...props} /> } exact />] : []),
									...(permissions.reductionApprove ? [<Route key="/offers-reduction-surplus" path="/offers-reduction-surplus" render={(props) => <OffersReduction type="surplus" {...props} /> } exact />] : []),
									...(permissions.reductionPriceSet ? [<Route key="/offers-reduction-commercial" path="/offers-reduction-commercial" render={(props) => <OffersReduction type="commercial" {...props} /> } exact />] : []),
									...(permissions.pricingConfirm ? [<Route key="/offers-reduction-pricing" path="/offers-reduction-pricing" render={(props) => <OffersReduction type="pricing" {...props} /> } exact />] : []),
									
									<Route key="/orders" path="/orders" component={OrdersHistory} exact />,
									<Route key="/analytics" path="/analytics" component={AnalyticsPage} exact />,
									...(permissions.stockAlertsCreate ? [<Route key="/stock-alerts" path="/stock-alerts" component={StockAlertsPage} exact />] : []),
								] : (
									// Render when isn't authenticated
									<Route path="/login" component={Login} exact />
								)}
	
								<Redirect to={isAuthenticated ? '/offers' : '/login'} />
							</IonRouterOutlet>
						</IonSplitPane>
					</Suspense>
				</IonReactRouter>}

				{activeModals.map(modal => <IonModal {...modal.options} key={modal.id} isOpen onDidDismiss={() => modal.dismiss()}>
					{modal.content} 
				</IonModal>)}
		</IonApp>
	);
};

export default App;
