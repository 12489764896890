import React from 'react';
import { useLocation } from 'react-router-dom';
import { logOutOutline, fileTrayStackedOutline, readerOutline, storefrontOutline, analyticsOutline, cashOutline, alertCircleOutline } from 'ionicons/icons';
import {
	IonContent,
	IonFooter,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonText,
} from '@ionic/react';

import InfoHelper from 'libraries/InfoHelper';
import Authentication, { usePermissions } from 'libraries/Authentication';

import './Menu.css';
import { useSelectedStore, useStores } from 'services/StoreService';
import { useStockAlertsCount } from 'services/StockAlertService';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	permissions?: string[];
}

const appPages: AppPage[] = [
	{ title: 'Ofertas', url: '/offers', icon: readerOutline },
	{ title: 'Reducción de precios', url: '/offers-reduction-store', icon: cashOutline, permissions: ['reductionCreate'] },
	{ title: 'Mermas', url: '/offers-reduction-surplus', icon: cashOutline, permissions: ['reductionApprove'] },
	{ title: 'Comercial', url: '/offers-reduction-commercial', icon: cashOutline, permissions: ['reductionPriceSet'] },
	{ title: 'Pricing', url: '/offers-reduction-pricing', icon: cashOutline, permissions: ['pricingConfirm'] },
	{ title: 'Actividad', url: '/orders', icon: fileTrayStackedOutline },
	{ title: 'Analytics', url: '/analytics', icon: analyticsOutline },
];

const Menu: React.FC = () => {
	const location = useLocation();
	const stores = useStores();
	const selectedStore = useSelectedStore();
	const permissions = usePermissions();
	const stockAlertsCount = useStockAlertsCount();

	function logout() {
		InfoHelper.showAlert({
			header: 'Cerrar sesión',
			subHeader: '¿Seguro que quiere cerrar sesión?',
			buttons: ['Cancelar', {
				text: 'Cerrar sesión',
				handler: async () => {
					await InfoHelper.showLoading();

					try {
						await Authentication.logout();
						// await RealTime.reset();

						// Reload page
						window.location.reload();

						await InfoHelper.hideLoading();
					}
					catch (error) {
						console.log(error);

						await InfoHelper.hideLoading();
						await InfoHelper.showErrorAlert();
					}
				}
			}]
		});
	}

	return (
		<IonMenu contentId="main" type="overlay">
			<IonContent>
				<IonList id="inbox-list">
					<IonListHeader>
						<img className='banner-menu' src="/assets/images/banner-by-carrefour-large.png" alt="WINIM By Carrefour" />
					</IonListHeader>
					{
						stores && stores.length === 1 && 
						<IonMenuToggle autoHide={false}>
							<IonItem 
							lines="full"
							detail={false}
							>
								<IonIcon slot="start" icon={storefrontOutline} />
								<IonLabel>{ selectedStore?.fullNameDescription}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					}
					{appPages.map((appPage, index) => {
						return (
							(!appPage.permissions || (
								appPage.permissions.reduce((p, c) => (permissions as any)[c] || p, false)
							)) && <IonMenuToggle key={index} autoHide={false}>
								<IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
									<IonIcon slot="start" icon={appPage.icon} />
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}

					{
						permissions.stockAlertsCreate && <IonMenuToggle autoHide={false}>
						<IonItem className={location.pathname === '/stock-alerts' ? 'selected' : ''} routerLink={'/stock-alerts'} routerDirection="root" lines="none" detail={false}>
								<IonIcon slot="start" icon={alertCircleOutline} />
								<IonLabel>
									<IonText color={stockAlertsCount.danger ? 'danger' : ''}>
										Seguimiento de stock {stockAlertsCount.danger ? `(${stockAlertsCount.danger})` : ''}
									</IonText>
								</IonLabel>
							</IonItem>
						</IonMenuToggle>
					}
				</IonList>
			</IonContent>
			<IonFooter>
				<IonList>
					
					<IonMenuToggle autoHide={false}>
						<IonItem lines="none" detail={false} onClick={logout} className="ion-activatable cursor-pointer">
							<IonIcon slot="start" icon={logOutOutline} />
							<IonLabel>Cerrar sesión</IonLabel>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonFooter>
		</IonMenu>
	);
};

export default Menu;
