import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Permissions')
export class Permissions {
	@JsonProperty('create_offers', Boolean) createOffers: boolean = false;
	@JsonProperty('delete_offers', Boolean) deleteOffers: boolean = false;
	@JsonProperty('edit_offers', Boolean) editOffers: boolean = false;
	@JsonProperty('approve_offers', Boolean) approveOffers: boolean = false;

	@JsonProperty('reduction_create', Boolean) reductionCreate: boolean = false;
	@JsonProperty('reduction_approve', Boolean) reductionApprove: boolean = false;
	@JsonProperty('reduction_price_set', Boolean) reductionPriceSet: boolean = false;
	@JsonProperty('pricing_confirm', Boolean) pricingConfirm: boolean = false;

	@JsonProperty('stock_alerts_create', Boolean) stockAlertsCreate: boolean = false;

}
