import { useState, useEffect } from 'react';
import { ValueCheckingMode, OperationMode, JsonConvert } from 'json2typescript';

import Server from 'libraries/Server'
import EventEmitter from 'libraries/EventEmitter'

import { SessionToken } from 'models/SessionToken'
import { Permissions } from 'models/Permissions'
import StoreService from 'services/StoreService';
import { User } from 'models/User';
import ModalController from './ModalController';
import UsersUpdatePassword from 'pages/Users/UsersUpdatePassword';
import React from 'react';

class Authentication extends EventEmitter {
	private _sessionToken: SessionToken | undefined = undefined;
	
	/**
	 * True when the user is authenticated
	 **/
	public _isAuthenticated: boolean = false;

	public set isAuthenticated(v : boolean) {
		this._isAuthenticated = v;

		this.emit('isAuthenticated', v);
	}

	public get isAuthenticated() : boolean {
		return this._isAuthenticated;
	}
	
	/**
	 * Here are stores the user permissions
	 **/
	public _permissions: Permissions = new Permissions();

	public set permissions(v : Permissions) {
		this._permissions = v;

		this.emit('permissions', v);
	}

	public get permissions() : Permissions {
		return this._permissions;
	}
	
	public initialized: boolean = false;

	private jsonConvert = new JsonConvert(OperationMode.ENABLE, ValueCheckingMode.ALLOW_NULL, true);
	
	public storageKey: string = 'com.winim.allies';

	/**
	 * Initialize the authentication library
	 **/
	public async initialize(): Promise<void> {
		if (!this.initialized) {
			this.initialized = true;
			
			const sessionTokenJSON = localStorage.getItem(`${this.storageKey}:sessionToken`);

			if (sessionTokenJSON) {
				await this.setSessionToken(this.jsonConvert.deserializeObject(JSON.parse(sessionTokenJSON), SessionToken));
				
				if (this.sessionToken?.isRefreshRequired) {
					await this.refreshSession();
				}
			}
			else {
				await this.setSessionToken(undefined);
			}

			this.handleInvalidCredentialsError();
			
			if (this.isAuthenticated) {
				await this.getPermissions();
			}
		}
	}

	/**
	 * Set session token
	 **/
	private async setSessionToken(sessionToken?: SessionToken): Promise<void> {
		this.sessionToken = sessionToken;

		if (sessionToken && !sessionToken.isRefreshRequired) {
			const stores = await StoreService.get();

			StoreService.setStateProperty('stores', stores);
			StoreService.setStateProperty('selectedStore', stores[0]);

			// Get current user
			const user = await this.getMe();

			// Check if require password update
			if (user.requirePasswordUpdate) {
				ModalController.create(React.createElement(UsersUpdatePassword), { backdropDismiss: false });
			}
		}
		else {
			StoreService.setStateProperty('stores', []);
			StoreService.setStateProperty('selectedStore', undefined);
		}
	}

	/**
	 * SessionToken getter
	 **/
	get sessionToken(): SessionToken | undefined {
		if (!this.initialized) {
			this.initialize();
		}

		return this._sessionToken;
	}

	/**
	 * SessionToken setter
	 **/
	set sessionToken(sessionToken: SessionToken | undefined){
		this._sessionToken = sessionToken;

		if (sessionToken) {
			Server.defaults.headers.common['Access-Token'] = btoa(sessionToken.accessToken);

			this.isAuthenticated = true;
		}
		else {
			delete Server.defaults.headers.common['Access-Token'];
			
			this.isAuthenticated = false;
		}

		this.initialized = true;
	}

	/**
	 * Login user in to the dashboard
	 *
	 * @param {string} email: User email
	 * @param {string} password: User password
	 **/
	async login(email: string, password: string): Promise<void>{
		const data = {
			email, 
			password, 
			type: 'password'
		};

		const res = await Server.post('v2/users/auth', data);

		await this.setSessionToken(this.jsonConvert.deserializeObject(res.data, SessionToken));
	
		await this.getPermissions();

		localStorage.setItem(`${this.storageKey}:sessionToken`, JSON.stringify(this.jsonConvert.serialize(this.sessionToken)));
		
	}

	/**
	 * Logout user
	 **/
	async logout(): Promise<void>{
		if (this.sessionToken) {
			await Server.delete('v2/users/auth');

			await this.setSessionToken(undefined);

			localStorage.removeItem(`${this.storageKey}:sessionToken`);
		}
	}

	/**
	 * Refresh session token
	 **/
	private async refreshSession(): Promise<void>{
		if (this.sessionToken) {
			const data = {
				refresh_token: this.sessionToken.refreshToken,
				type: 'refresh_token'
			};

			try {
				const res = await Server.post('v2/users/auth', data);
				
				await this.setSessionToken(this.jsonConvert.deserializeObject(res.data, SessionToken));
				
				localStorage.setItem(`${this.storageKey}:sessionToken`, JSON.stringify(this.jsonConvert.serialize(this.sessionToken)));
			} catch (error) {
				console.log('refreshSession error');
				console.log(error);

				await this.logout();
			}
		}
	}

	/**
	 * Get user permissions from the server
	 **/
	private async getPermissions(): Promise<void>{
		const res = await Server.get('v2/users/permissions');

		this.permissions = this.jsonConvert.deserializeObject(res.data, Permissions);
	}

	/**
	 * Get user
	 **/
	public async getMe(): Promise<User>{
		const res = await Server.get('v2/users/me');

		return this.jsonConvert.deserializeObject(res.data, User);
	}

	/**
	 * Update user password
	 **/
	public async updatePassword(data: any): Promise<User>{
		const res = await Server.put('v2/users/me/password', data);

		return this.jsonConvert.deserializeObject(res.data, User);
	}

	private handleInvalidCredentialsError() {
		Server.interceptors.response.use(res => res, error => {
			if (error?.response?.status === 401) {
				this.logout();
			}

			return Promise.reject(error);
		});
	}
}

const authentication = new Authentication();

export function useIsAuthenticated() {
	const [isAuthenticated, setIsAuthenticated] = useState(authentication.isAuthenticated);
	
	useEffect(() => {
		const id = authentication.on('isAuthenticated', setIsAuthenticated);

		return () => {
			authentication.removeListener('isAuthenticated', id);
		};
	}, [isAuthenticated]);

	return isAuthenticated;
}

export function usePermissions() {
	const [permissions, setPermissions] = useState(authentication.permissions);
	
	useEffect(() => {
		const id = authentication.on('permissions', setPermissions);

		return () => {
			authentication.removeListener('permissions', id);
		};
	}, [permissions]);

	return permissions;
}


export default authentication;