import React, { ReactComponentElement } from 'react';

import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { IonIcon, IonText } from '@ionic/react';
import { alertCircle, alertCircleSharp, atOutline, checkmarkCircleSharp, warning, warningSharp } from 'ionicons/icons';


const lightIcons = {
	'green' : <IonText color='success'><IonIcon icon={checkmarkCircleSharp}></IonIcon></IonText>,
	'yellow' : <IonText color='warning'><IonIcon icon={warningSharp}></IonIcon></IonText>,
	'red' : <IonText color='danger'><IonIcon icon={alertCircleSharp}></IonIcon></IonText>
}

@JsonObject('StockAlertCount')
export class StockAlertCount {
	@JsonProperty('danger', Number) danger: number = 0;
}

@JsonObject('StockAlert')
export class StockAlert {
	@JsonProperty('id', String, true) id?: string;
	@JsonProperty('ean', String) ean: string = '';
	@JsonProperty('description', String) description: string = '';
	@JsonProperty('expiration_at', String) expirationAt: string = '';
	@JsonProperty('warning_at', String) warningAt: string = '';
	@JsonProperty('danger_at', String) dangerAt: string = '';
	@JsonProperty('units', Number, true) units?: number;
	@JsonProperty('status', String) status: string = '';
	@JsonProperty('updated_at', String) updatedAt: string = '';
	@JsonProperty('store', Any) store: any = {};

	public get lightIcon() : JSX.Element {
		if (new Date() >= new Date(this.dangerAt)) {
			return lightIcons.red
		}
		else if (new Date() >= new Date(this.warningAt)) {
			return lightIcons.yellow
		}
		
		return lightIcons.green;
	}
}
