import React, { useEffect, useState } from 'react';
import { 
	IonContent,
	IonPage,
	IonButton,
	IonFooter,
	IonInput,
	IonLabel,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonText,
} from '@ionic/react';

import InfoHelper from 'libraries/InfoHelper';
import Authentication from 'libraries/Authentication';

import DataError from 'components/DataError';
import ModalController from 'libraries/ModalController';
import DataErrors from 'libraries/DataErrors';

import './UsersUpdatePassword.scss';

const UsersUpdatePassword: React.FC = () => {
	const [ currentPassword, setCurrentPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');
	const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');

	async function submit() {
		await InfoHelper.showLoading();

		try {
			let data = {
				current_password: currentPassword,
				new_password: newPassword
			};

			const user = await Authentication.updatePassword(data);

			await InfoHelper.hideLoading();
			
			await InfoHelper.showAlert({
				subHeader: 'Contraseña cambiada correctamente',
				buttons: ['Entendido']
			});

			ModalController.dismiss(user);
		}
		catch (error) {
			console.log(error);

			await InfoHelper.hideLoading();

			if (!(error as any)?.response?.data?.data_errors) {
				await InfoHelper.showErrorAlert();
			}
		}
	}

	function allowSubmit(){
		return currentPassword && newPassword && newPassword.length >= 6 && newPassword === newPasswordConfirm
	}

	useEffect(() => {
		const errors: any = {};

		if (newPassword && newPassword.length < 6) {
			errors.new_password = 'Tu nueva contraseña debe ser de almenos 6 caracteres';
		}

		if (newPasswordConfirm && newPassword !== newPasswordConfirm) {
			errors.new_password_confirm = 'Las contraseñas no coinciden';
		}

		DataErrors.errors = errors;

	}, [currentPassword, newPassword, newPasswordConfirm]);

	return (
		<IonPage className="offers-edit-page">
			<IonHeader>
				<IonToolbar>
					<IonTitle>Cambiar contraseña</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<div className='ion-text-center ion-margin-bottom'>
					<IonText color='warning'>
						Debe cambiar su contraseña para continuar
					</IonText>
				</div>

				{/* Current password */}
				<IonLabel position="stacked">Contraseña actual</IonLabel>
				<IonInput value={currentPassword} type='password' onIonChange={e => setCurrentPassword(e.detail.value!)} placeholder="Ingresa tu contraseña actual"></IonInput>
				<DataError name="current_password" />

				{/* New password */}
				<IonLabel position="stacked">Contraseña nueva</IonLabel>
				<IonInput value={newPassword} type='password' onIonChange={e => setNewPassword(e.detail.value!)} placeholder="Ingresa tu nueva contraseña"></IonInput>
				<DataError name="new_password" />

				{/* Nwe password confirm */}
				<IonLabel position="stacked">Contraseña actual</IonLabel>
				<IonInput value={newPasswordConfirm} type='password' onIonChange={e => setNewPasswordConfirm(e.detail.value!)} placeholder="Repite tu nueva contraseña"></IonInput>
				<DataError name="new_password_confirm" />
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonButtons slot='end'>
						{/* Save button */}
						<IonButton color="success" disabled={!allowSubmit()} onClick={() => submit()}>Guardar</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonFooter>
		</IonPage>
	);
};

export default UsersUpdatePassword;
