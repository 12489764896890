import { Store } from 'models/Store'

import Server from 'libraries/Server'
import StateStore from 'libraries/StateStore';
import JsonConverter from 'libraries/JsonConverter';
import { useEffect, useState } from 'react';

class StoreService extends StateStore {
	
	constructor () {
        super();

        this.setStateProperty('stores', []);
    }

	/**
	 * Get all stores
	 **/
	async get(): Promise<Array<Store>>{
		const res = await Server.get('v2/stores');

		return JsonConverter.deserializeArray(res.data, Store);
	}
	
	/**
	 * Edit store
	 *
	 * @param {string} id Store id
	 * @param {any} data Store data
	 **/
	async edit(id: string, data: any): Promise<Store>{
		const res = await Server.put(`v2/stores/${id}`, data);

		return JsonConverter.deserializeObject(res.data, Store);
	}
	
	/**
	 * Get store details
	 *
	 * @param {string} id Store id
	 **/
	async details(id: string): Promise<Store>{
		const res = await Server.get(`v2/stores/${id}`);

		return JsonConverter.deserializeObject(res.data, Store);
	}
}

const storeService = new StoreService();

export function useStores () {
    const [stores, setStores] = useState(storeService.getStateProperty('stores') as Store[]);

    useEffect(() => {
        const id = storeService.on('stores', setStores);

        return () => {
            storeService.removeListener('stores', id);
        };
    }, [stores]);

    return stores;
}

export function useSelectedStore () {
    const [selectedStore, setSelectedStore] = useState(storeService.getStateProperty('selectedStore') as Store | undefined);

    useEffect(() => {
        const id = storeService.on('selectedStore', setSelectedStore);

        return () => {
            storeService.removeListener('selectedStore', id);
        };
    }, [selectedStore]);

    return selectedStore;
}


export default storeService;