import { StockAlert, StockAlertCount } from 'models/StockAlert'

import Server from 'libraries/Server'
import StateStore from 'libraries/StateStore';
import JsonConverter from 'libraries/JsonConverter';
import { useEffect, useState } from 'react';
import Authentication from 'libraries/Authentication';

class StockAlertService extends StateStore {
	
	/**
	 * To know if the service is initialized
	 */
	private initialized: boolean = false;


	constructor () {
        super();

        this.setStateProperty('stockAlertsCount', new StockAlertCount());
    }
	
	/**
	 * Initialize service
	 **/
	public async initialize() {
		if (this.initialized) return;

		if (Authentication.isAuthenticated) {
			await this.getCount();
		}

		this.initialized = true;
	}

	/**
	 * Get all stockAlerts
	 * 
	 * @param {any} params Query params
	 **/
	async get(params: any): Promise<Array<StockAlert>>{
		const res = await Server.get('v2/stock-alerts', { params });

		return JsonConverter.deserializeArray(res.data, StockAlert);
	}

	/**
	 * Get stockAlerts count
	 **/
	async getCount(): Promise<StockAlertCount>{
		const res = await Server.get('v2/stock-alerts/count');

		const count = JsonConverter.deserializeObject(res.data, StockAlertCount);

        this.setStateProperty('stockAlertsCount', count);

		return count;
	}
	
	/**
	 * Create stockAlert
	 *
	 * @param {any} data StockAlert data
	 **/
	async create(data: any): Promise<StockAlert>{
		const res = await Server.post('v2/stock-alerts', data);

		this.getCount().catch(() => {});

		return JsonConverter.deserializeObject(res.data, StockAlert);
	}
	
	/**
	 * Edit stockAlert
	 *
	 * @param {string} id StockAlert id
	 * @param {any} data StockAlert data
	 **/
	async edit(id: string, data: any): Promise<StockAlert>{
		const res = await Server.put(`v2/stock-alerts/${id}`, data);

		this.getCount().catch(() => {});

		return JsonConverter.deserializeObject(res.data, StockAlert);
	}
	
	/**
	 * Get stockAlert details
	 *
	 * @param {string} id StockAlert id
	 **/
	async details(id: string): Promise<StockAlert>{
		const res = await Server.get(`v2/stock-alerts/${id}`);

		return JsonConverter.deserializeObject(res.data, StockAlert);
	}
}

const stockAlertService = new StockAlertService();

export function useStockAlertsCount () {
    const [stockAlertsCount, setStockAlertsCount] = useState(stockAlertService.getStateProperty('stockAlertsCount') as StockAlertCount);

    useEffect(() => {
        const id = stockAlertService.on('stockAlertsCount', setStockAlertsCount);

        return () => {
            stockAlertService.removeListener('stockAlertsCount', id);
        };
    }, [stockAlertsCount]);

    return stockAlertsCount;
}

export default stockAlertService;